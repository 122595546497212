<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="nombre"
                type="text"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombre),
                    rules.maxLength(nombre, 50)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="codigo"
                label="Código"
                type="text"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(codigo),
                    rules.maxLength(codigo, 5)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditOtrasAfiliaciones",
  props: {
    tipoAfiliacionObj: {
      type: Array,
      required: true
    },
    editTipoAfiliacionItem: {
      type: Object
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_TIPO_AFILIACION,
    newTitle: enums.titles.NUEVO_TIPO_AFILIACION,
    rules: rules,
    nombre: "",
    codigo: ""
  }),
  created() {
    if (this.editTipoAfiliacionItem) {
      this.setTiposAfiliaciones(this.editTipoAfiliacionItem);
    } else {
      this.newTipoAfiliacion();
    }
  },
  methods: {
    ...mapActions({
      saveOtrasAfiliaciones: "configAfiliaciones/saveOtrasAfiliaciones",
      setAlert: "user/setAlert"
    }),
    async setTiposAfiliaciones(obj) {
      this.otroTipoNumeroAfiliadoId = obj.otroTipoNumeroAfiliadoId;
      this.nombre = obj.nombre;
      this.codigo = obj.codigo;
    },
    async newTipoAfiliacion() {
      this.formEditTitle = this.newTitle;
    },

    async save() {
      let data = {
        nombre: this.nombre,
        codigo: this.codigo,
        otroTipoNumeroAfiliadoId: this.editTipoAfiliacionItem
          ?.otroTipoNumeroAfiliadoId
      };
      const res = await this.saveOtrasAfiliaciones(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
